<template>
    <div class="flex items-center" :class="height">
        <div class="loading loading01 mx-auto text-blue-200 dark:text-slate-700">
            <span>L</span>
            <span>O</span>
            <span>A</span>
            <span>D</span>
            <span>I</span>
            <span>N</span>
            <span>G</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
          height: {
                type: String,
                default: () => "min-h-screen",
            },
        }
    }
</script>

<style scoped>
/* common */
.loading {
  font-size: 50px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-align: center;
  letter-spacing: 1px;
}
.loading span {
  display: inline-block;
  margin: 0 -0.05em;
}

/* code #1 */
.loading01 span {
  animation: loading01 1.4s infinite alternate;
}
.loading01 span:nth-child(1) {
  animation-delay: 0s;
}
.loading01 span:nth-child(2) {
  animation-delay: 0.1s;
}
.loading01 span:nth-child(3) {
  animation-delay: 0.2s;
}
.loading01 span:nth-child(4) {
  animation-delay: 0.3s;
}
.loading01 span:nth-child(5) {
  animation-delay: 0.4s;
}
.loading01 span:nth-child(6) {
  animation-delay: 0.5s;
}
.loading01 span:nth-child(7) {
  animation-delay: 0.6s;
}

@keyframes loading01 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
