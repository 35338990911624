<template>
    <!-- <div class="max-w-screen-xl mx-auto"> -->

        <Loading v-if="loading" height="h-[calc(100vh-20rem)]" />
        <div v-if="errors.value !== ''" class="bg-red-300 text-red-900 rounded-lg p-4 mx-4 mb-12 font-semibold">
            {{ errors.value }}
        </div>
        <Transition v-else mode="out-in">
            <div v-if="!loading">
                <div class="flex flex-row justify-between">
                    <h4 class="dark:text-white text-xl mb-10 border-b-2 border-current">{{ $t('accountsummary') }}</h4>
                    <router-link :to="{name: 'list-bookings'}"><i class="fa-regular fa-circle-xmark text-rose-400 hover:text-rose-500 text-2xl"></i></router-link>
                </div>

                <BookingHeader v-bind="data" />

                <div class="hidden md:grid grid-cols-7 pb-3 mt-4 text-sky-600 text-base font-bold border-b border-gray-300 dark:border-slate-700">
                    <div class="col-span-1">
                        {{ $t('date') }}
                    </div>
                    <div class="col-span-1">
                        {{ $t('reference') }}
                    </div>
                    <div class="col-span-1">
                        {{ $t('relatedref') }}
                    </div>
                    <div class="col-span-1 text-center">
                        {{ $t('type') }}
                    </div>
                    <div class="col-span-2">
                        {{ $t('invoices') }}
                    </div>
                    <div class="col-span-1 text-right">
                        {{ $t('value') }}
                    </div>
                </div>
                <div class="dark:bg-slate-900 pt-4 pb-1">
                    <div v-if="!data.Transactions" class="rounded-lg p-4 mb-4 font-semibold text-center">
                        No transactions on this booking yet.
                    </div>
                    <div v-else v-for="(transaction, index) in data.Transactions" :key="transaction.TransactionId" class="md:grid md:grid-cols-7 mb-3">
                        <div class="col-span-1"><span class="inline md:hidden">Transaction Date: </span>{{ $readableDateAndYear(transaction.Date) }}</div>
                        <div class="col-span-1"><span v-if="transaction.TransactionRef !== ''" class="inline md:hidden">Transaction Reference: </span>{{ transaction.TransactionRef }}</div>
                        <div class="col-span-1"><span class="inline md:hidden" v-if="transaction.TransactionRef !== transaction.RelatedRef && transaction.RelatedRef !== ''">Related Reference: {{ transaction.RelatedRef }}</span></div>
                        <div class="col-span-1 text-center"><span class="inline md:hidden">Transaction Type: </span>{{ transaction.TransactionType }}</div>
                        <div class="col-span-2">
                            <div v-if="transaction.TransactionType === 'INV' && !singleLoading[index]">
                                <span v-if="transaction.InvoiceUrl"><a :href="transaction.InvoiceUrl" target="_blank">{{ $t('viewinvoice') }}<i class="dark:text-white fa-solid fa-arrow-up-right-from-square ml-2 text-xs align-text-top"></i></a></span>

                            </div>
                            <div v-if="singleLoading[index]">
                                <div class="lds-ellipsis mx-auto"><div></div><div></div><div></div><div></div></div>
                            </div>
                        </div>
                        <div class="col-span-1 md:text-right"><span class="inline md:hidden">Transaction Value: </span>{{ $priceFormat(transaction.Amount, company) }}</div>
                    </div>
                </div>
            </div>
        </Transition>
    <!-- </div> -->

</template>

<script>
import axios from 'axios'
import { Field, Form, ErrorMessage } from 'vee-validate'
import Loading from '../../micro/Loading.vue'
import BookingHeader from '../../micro/BookingHeader.vue'

const company = import.meta.env.VITE_COMPANY_NAME

export default {
    components: {
        Field,
        Form,
        ErrorMessage,
        Loading,
        BookingHeader
    },
    data() {
        return {
            data: [],
            loading: true,
            errors: {
                value: ''
            },
            invUrl: [],
            singleLoading: [],
            company
        }
    },

    mounted() {
        this.isUser()
    },

    methods: {
        // Check admin
        isUser() {
            axios.get('/api/is-user')
            .then(() => {
                this.list()
            })
            .catch(() => {
                this.$router.push({name: 'user-login', query: { from: (this.$route.name)}})
            });
        },
        // Fetch invoices
        async list(){
            //
            const ref = this.$route.params.ref
            await axios
            .get('/api/my-invoices/' + ref)
            .then(({data})=>{
                console.log(data)
                this.data = data
                this.loading = false;
                this.singleLoading = [];
            })
            .catch(err => {
                console.log(err)
                this.errors.value = err.response.data.message
                this.loading = false;
            })
        },

    }
}
</script>
