<template>
    <button v-bind="$attrs" class="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"><i :class="icon" ></i>{{ buttonText }}</button>
</template>

<script>
    export default {
        props: {
            buttonText: {
                type: String,
                default: () => "Submit",
            },
            icon: {
                type: String,
                default: () => ''
            },
            // px: {
            //     type: String,
            //     default: () => 'px-6'
            // }
        }
    }
</script>

<style scoped>

</style>