<template>
    <div class="absolute p-6 w-full flex justify-end ">
        <DarkMode divWidth="w-10" />
    </div>
    <div class="flex items-center min-h-screen">
        <div class="w-96 mx-auto bg-white border border-gray-200 rounded-lg shadow p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
            <Loading v-if="loading" />
            <div v-if="errors.value != ''" class="bg-red-300 text-red-900 rounded-lg p-4 mb-12 font-semibold max-w-4xl m-auto">
                {{ errors.value }}
            </div>

            <h4 class="text-gray-900 dark:text-white text-xl mb-10 border-current">Find Your Travel App</h4>

            <p class="font-normal">Please enter your {{ companyName }} booking reference. </p>
            <Form @submit="findItinerary" class="flex flex-col mt-6 space-y-7">
                <div>
                    <label for="ref" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ $t('reference') }}:</label>
                    <Field
                        name="ref"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        type="text"
                        placeholder="Booking Reference"
                        v-model="newRef"
                        rules="required"
                    />
                    <ErrorMessage name="ref" class="text-sm text-red-300" />
                </div>
                <submit-button buttonText="Find Itinerary" />
            </Form>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import { Field, Form, ErrorMessage } from 'vee-validate'
import Loading from '../micro/Loading.vue'
import SubmitButton from '../micro/SubmitButton.vue'
import DarkMode from '../common/DarkMode.vue'

const companyName = import.meta.env.VITE_COMPANY_NAME

export default {
    components: {
        Field,
        Form,
        ErrorMessage,
        Loading,
        SubmitButton,
        DarkMode
    },
    data() {
        return {

            loading: false,
            errors: {
                value: ''
            },
            noId: false,
            newRef: '',
            companyName
            // myImg
        }
    },

    mounted() {

    },


    methods: {

        findItinerary() {
            console.log(this.newRef)
            axios
            .get('/api/find-itinerary/' + this.newRef)
            .then(({data})=>{
                console.log(data)
                this.$router.push({path: '/travel-app/' + data})

            })
            .catch(err => {
                console.log(err)
                this.errors.value = err.response.data.message
                this.loading = false;
            })
        }

    }
}
</script>
