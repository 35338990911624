const myPlugin = {
    install(app) {

        // Price formatting
        app.config.globalProperties.$priceFormat = (price, company) => {

            if (company === 'Pacific Destinations') {
                return Number(price / 100).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'NZD'
                })
            } else if (company === 'Goway Inbound') {
                return Number(price / 100).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'AUD'
                })
            } else {
                return Number(price / 100).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'NZD'
                })
            }
        }

        // Strip pre tags
        app.config.globalProperties.$stripPreTags = (key) => {

            return key.replace(/<pre data-placeholder="Translation" dir="ltr">(.*?)<\/pre>/gs, '$1')

        }

        // Convert to friendly date
        app.config.globalProperties.$readableDate = (key) => {
            const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var monthNum = key.substring(5,7)
            var month = months[monthNum -1]
            var day = key.substring(8,10)

            return day + ' ' + month
        }

        // Convert to friendly date including year
        app.config.globalProperties.$readableDateAndYear = (key) => {
            const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var monthNum = key.substring(5,7)
            var year = key.substring(0,4)
            var month = months[monthNum -1]
            var day = key.substring(8,10)

            return day + ' ' + month + ' ' + year
        }

        // Convert room code to room name
        app.config.globalProperties.$roomName = (key) => {
            if ( key === 'DB') {
                return 'Double Room'
            } else if ( key === 'SG') {
                return 'Single Room'
            } else if ( key === 'TW') {
                return 'Twin Room'
            } else if ( key === 'TR') {
                return 'Triple Room'
            } else if ( key === 'QD') {
                return 'Quad Room'
            } else if ( key === 'OT'){
                return 'Other Room'
            } else {
                return ''
            }
        }

        // Convert room code to room name
        app.config.globalProperties.$readableTime = (time) => {
            return time.slice(0, 2) + ':' + time.slice(2)
        }

        // Trim string length
        app.config.globalProperties.$trimString = (string, length) => {
            if (string.length > length) {
                return string.slice(0, length) + '...'
            } else {
                return string
            }
        }

        // Remove -cl from supplier confirmations
        app.config.globalProperties.$cleanSupplierConfirmation = (confirmation) => {
            confirmation = confirmation.replaceAll(' -cl', '');
            confirmation = confirmation.replaceAll(' -ar', '');
            confirmation = confirmation.replaceAll(' -rc', '');
            return confirmation
        }

        //  Pluralise based on a quantity
        app.config.globalProperties.$pluralise = (quantity) => {
            if ( quantity > 1) {
                return 's'
            }
        }

    }
}

export default myPlugin;
